import React from 'react'
import { T } from '../../typo'
import { s, globals } from '../../style'
import { Grid } from '@material-ui/core'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  sBlogWrapper,
  sBlog,
  sBlogTheme,
  sImage,
  sTitle,
} from '../../pages/BlogsSearch/BlogsSearch'

const BriefMinis = ({ pageData, data, theme }) => {
  // console.log(data)
  const { title, subtitle, description } = pageData
  return (
    <div css={sContainer}>
      <div css={sIntro}>
        <T
          d={64}
          m={48}
          bold
          variant="h2"
          extraCss={{
            marginBottom: '0.5rem',
          }}>
          {title}
        </T>
        {subtitle && <T>{subtitle}</T>}
        <T>{description}</T>
      </div>
      <T d={32} m={32} bold upper o={0.3} mb={1}>
        {data.title}
      </T>
      <Grid container>
        {data?.briefs.map((brief, id) => (
          <Grid xs={12} sm={6} md={4} item css={sBlogWrapper} key={id}>
            <Link
              to={`${brief?.file?.publicURL}`}
              key={id}
              css={[sBlog, sBlogTheme(theme), { height: 'max-content' }]}>
              <div css={sImage}>
                {brief.image?.childImageSharp?.fluid && (
                  <Img
                    style={sImage.image}
                    fluid={brief.image.childImageSharp.fluid}
                  />
                )}
              </div>
              <div css={sTitle}>{brief.title}</div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const sContainer = {
  margin: '0 auto',
  maxWidth: globals.maxWidth,
  [s.lg_1280]: {
    padding: '0 2rem',
  },
  marginBottom: '4rem',
  [s.md]: {
    padding: '0 2rem',
  },
  [s.sm_down]: {
    padding: 0,
  },
}

const sIntro = {
  marginBottom: '4rem',
  paddingTop: '2rem',
  [s.md]: { paddingTop: '2rem' },
  [s.sm_down]: { textAlign: 'center', marginBottom: '4rem' },
}

export default BriefMinis
