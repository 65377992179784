import React, { useState } from 'react'
import { T } from '../../typo'
import { s, globals, colors, alpha } from '../../style'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { sTextInput } from '../../forms/FormItems/FormInputs'
import { TextField, Grid } from '@material-ui/core'
import { string_to_slug } from '../../helpers'
import Img from 'gatsby-image'

const BlogsSearch = ({ theme, pageData }) => {
  // console.log(pageData)
  const language = pageData.language
  const [searchInput, setSearchInput] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const onValueChange = (event) => {
    event.preventDefault()
    setSearchInput(event.target.value)
    setSelectedCategory(null)
  }
  const results = useStaticQuery(graphql`
    {
      blogs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/blog/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              url
              language
              blog_slug
              author
              description
              blog_category
              date
              image {
                name
                publicURL
                childImageSharp {
                  fluid(
                    maxWidth: 400
                    quality: 100
                    toFormat: JPG
                    jpegProgressive: true
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      categories: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/categories-blog/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
            }
          }
        }
      }
      # authors: allMarkdownRemark(
      #   filter: { fileAbsolutePath: { regex: "/content/authors/" } }
      # ) {
      #   edges {
      #     node {
      #       frontmatter {
      #         title
      #         subtitle
      #         description
      #         image {
      #           name
      #           childImageSharp {
      #             fluid(maxWidth: 300, quality: 100) {
      #               ...GatsbyImageSharpFluid
      #             }
      #           }
      #         }
      #       }
      #     }
      #   }
      # }
    }
  `)

  const blogs = results.blogs.edges.map(({ node }) => node.frontmatter)
  const categories = results.categories.edges.map(
    ({ node }) => node.frontmatter
  )
  const blogsExisting = blogs
    .filter((blog) => blog.title && blog.language === language && blog.image)
    .filter((blog) =>
      selectedCategory ? blog.blog_category === selectedCategory : true
    )
    .filter((blog) =>
      searchInput.length > 0
        ? blog.title.toLowerCase().includes(searchInput.toLowerCase()) ||
          blog.blog_category
            ?.toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          blog.author?.toLowerCase().includes(searchInput.toLowerCase())
        : true
    )

  return (
    <div css={sContainer}>
      <div css={sIntro}>
        <T
          d={64}
          m={48}
          bold
          variant="h1"
          extraCss={{
            marginBottom: '0.5rem',
          }}>
          {pageData.title}
        </T>
        {pageData.subtitle && <T>{pageData.subtitle}</T>}
        <T>{pageData.description}</T>
      </div>
      <div
        css={{
          [s.xs]: { margin: '0rem 1rem' },
          [s.sm_only]: { margin: '0rem 2rem' },
          paddingBottom: '1rem',
        }}>
        <TextField
          onChange={onValueChange}
          name="search"
          value={searchInput}
          variant="filled"
          label="Szukaj Artykułu"
          css={[sTextInput(theme)]}
          fullWidth
        />
      </div>
      <div css={sCategories}>
        <div
          onClick={() => setSelectedCategory(null)}
          onKeyPress={() => setSelectedCategory(null)}
          tabIndex={0}
          role="button"
          css={[
            sCategory(theme),
            selectedCategory === null && sCategory(theme).active,
          ]}>
          Wszystkie
        </div>
        {categories
          .sort((a, b) => a.order - b.order)
          .map((category, id) => (
            <div
              key={id}
              onClick={() => setSelectedCategory(category.title)}
              onKeyPress={() => setSelectedCategory(category.title)}
              tabIndex={0}
              role="button"
              css={[
                sCategory(theme),
                selectedCategory === category.title && sCategory(theme).active,
              ]}>
              {category.title}
            </div>
          ))}
      </div>

      <Grid container>
        {blogsExisting.map((blog, id) => (
          <Grid xs={12} sm={6} md={4} item css={sBlogWrapper} key={id}>
            <Link
              to={`/${
                blog.blog_slug ? blog.blog_slug : string_to_slug(blog.title)
              }`}
              key={id}
              css={[
                sBlog,
                sBlogTheme(theme),
                // !blog.blog_slug && { filter: 'grayscale(1)', opacity: 0.3 },
              ]}>
              <div css={sImage}>
                {blog.image?.childImageSharp?.fluid && (
                  <Img
                    style={sImage.image}
                    fluid={blog.image.childImageSharp.fluid}
                  />
                )}
              </div>
              <div css={sTitle}>{blog.title}</div>
              <div css={sDescription}>{blog.description}</div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export const sCategory = (theme) => ({
  display: 'flex',
  flexShrink: 0,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2rem',
  fontWeight: 800,
  letterSpacing: '-0.03em',
  cursor: 'pointer',
  transition:
    'color 0.25s ease-out, background-color 0.25s ease-out, filter 0.25s ease-out',
  backgroundColor: colors[theme].text.concat(alpha[8]),
  color: colors[theme].text.concat(alpha[60]),
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors[theme].text.concat(alpha[30]),
      color: colors[theme].text.concat(alpha[100]),
    },
  },

  [s.sm_down]: {
    fontSize: '0.875rem',
    margin: '0rem 1rem 1rem 0rem',
    padding: '0.5rem 1rem',
  },
  [s.md]: {
    fontSize: '1.125rem',
    margin: '0rem 1rem 1rem 0rem',
    padding: '0.875rem 1.5rem',
  },
  active: {
    backgroundColor: colors[theme].main.concat(alpha[100]),
    color: colors.white.concat(alpha[100]),

    '&:hover': {
      [s.hover]: {
        backgroundColor: colors[theme].main.concat(alpha[100]),
        color: colors.white.concat(alpha[100]),
        filter: 'brightness(120%)',
      },
    },
  },
})

export const sCategories = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '2rem 0rem',
  [s.sm_down]: {
    margin: '2rem 0rem',
    padding: '0 1rem',
    overflow: 'scroll',
  },
  [s.md]: {
    flexWrap: 'wrap',
  },
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
}

export const sBlogWrapper = {
  [s.xs]: { padding: '0rem 1rem 1rem' },
  [s.sm]: { padding: '1rem' },
  [s.md]: { padding: '1rem' },
}

export const sBlog = {
  transition: 'transform 0.25s ease-out, box-shadow 0.25s ease-out',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  flexShrink: 0,
  width: '100%',
  height: 344,
  borderRadius: 16,
  // [s.xs]: {
  //   width: 'calc(100vw - 48px)',
  // },
  boxShadow: `0px 8px 16px ${colors.black.concat(alpha[4])}`,
  '&:hover': {
    [s.hover]: {
      transform: 'scale(1.05) translateY(-16px)',
      boxShadow: `0px 8px 32px ${colors.black.concat(alpha[16])}`,
    },
  },
}

export const sBlogTheme = (theme) => ({
  backgroundColor: colors[theme].container,
})

export const sImage = {
  position: 'relative',
  width: '100%',
  height: 200,
  borderRadius: '16px 16px 0 0',

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  },
}

export const sTitle = {
  fontSize: 16,
  letterSpacing: -0.3,
  // minHeight: 36,
  fontWeight: 700,
  margin: '1.5rem 1.5rem 1rem',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}
export const sDescription = {
  fontSize: 13,
  letterSpacing: 0,
  maxHeight: 36,
  fontWeight: 400,
  margin: '0rem 1.5rem 1.5rem',
  overflow: 'hidden',
  opacity: 0.6,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}

export const sContainer = {
  margin: '0 auto',
  maxWidth: globals.maxWidth,
  [s.lg_1280]: {
    padding: '0 2rem',
  },
  marginBottom: '4rem',
  [s.md]: {
    padding: '0 2rem',
  },
  [s.sm_down]: {
    padding: 0,
  },
}

export const sIntro = {
  marginBottom: '4rem',
  paddingTop: '2rem',
  [s.md]: { paddingTop: '2rem' },
  [s.sm_down]: { textAlign: 'center', marginBottom: '4rem' },
}

export default BlogsSearch
