import React, { useRef, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s } from '../../style'
import { WrapperSection } from '../../wrappers'
// import momonet from 'moment'
import { string_to_slug } from '../../helpers'
import { ScrollDots } from '../../elements'
import { ButtonText } from '../../buttons'
import { useOnScreen } from '../../hooks'
import ScrollContainer from 'react-indiana-drag-scroll'

import {
  sDots,
  sBlogs,
  sHeadline,
  sBlog,
  sBlogTheme,
  sImage,
  sTitle,
  sDescription,
} from '../BlogMinis/BlogMinis'

const CareerMinis = ({ theme, data, removeMarginTop }) => {
  const { slug_section, title, language } = data
  const refBlogs = useRef(null)
  const onScreen = useOnScreen(refBlogs)
  const query = useStaticQuery(graphql`
    {
      careers: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/careers/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              url
              language
              career_slug
              author
              description
              date
              image {
                name
                publicURL
                childImageSharp {
                  fluid(
                    maxWidth: 400
                    quality: 100
                    toFormat: JPG
                    jpegProgressive: true
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // const authors = query.authors.edges.map(({ node }) => node.frontmatter)
  const blogs = query.careers.edges.map(({ node }) => node.frontmatter)
  const blogsExisting = blogs.filter(
    (blog) => blog.title && blog.language === language && blog.image
  )
  // console.log(authors)
  // console.log(blogsExisting)

  const button = {
    text: 'Wszystkie',
    link_page: 'kariera',
  }

  useEffect(() => {
    setTimeout(() => refBlogs.current.scrollTo({ left: 1 }), 0)
  }, [])

  return (
    <>
      <WrapperSection
        id={slug_section}
        removeMarginTop={removeMarginTop}
        extraCss={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          [s.md]: {
            marginBottom: '2rem',
          },
          contentVisibility: 'auto',
        }}>
        <T
          d={32}
          m={32}
          o={0.16}
          bold
          condensed
          upper
          extraCss={sHeadline}
          onScreen={onScreen}>
          {title}
        </T>
        {button && (
          <ButtonText language={language} theme={theme} button={button} o={0.3}>
            <T upper bold onScreen={onScreen}>
              {button?.text}
            </T>
          </ButtonText>
        )}
      </WrapperSection>
      <div>
        <ScrollContainer
          nativeMobileScroll={true}
          css={sBlogs}
          innerRef={refBlogs}>
          {blogsExisting
            // .sort((a, b) => momonet(b.date).unix() - momonet(a.date).unix())
            .map((blog, id) => (
              <div
                css={[
                  { padding: '0 1.5rem' },
                  onScreen === false && {
                    opacity: 0,
                    /* contentVisibility: 'hidden' */
                  },
                  {
                    transition: `opacity 0.5s ease-in-out ${
                      0.25 * id - 0.1 * id
                    }s`,
                  },
                ]}
                key={id}>
                <Link
                  to={`/${
                    blog.career_slug
                      ? blog.career_slug
                      : string_to_slug(blog.title)
                  }`}
                  key={id}
                  css={[
                    sBlog,
                    sBlogTheme(theme),
                    // !blog.career_slug && { filter: 'grayscale(1)', opacity: 0.3 },
                  ]}>
                  <div css={sImage}>
                    {blog.image?.childImageSharp?.fluid && (
                      <Img
                        style={sImage.image}
                        fluid={blog.image.childImageSharp.fluid}
                      />
                    )}
                  </div>
                  <div css={sTitle}>{blog.title}</div>
                  <div css={sDescription}>{blog.description}</div>
                </Link>
              </div>
            ))}
        </ScrollContainer>
      </div>
      <ScrollDots
        onScreen={onScreen}
        refElement={refBlogs}
        arr={blogsExisting}
        extraCss={sDots(theme)}
      />
    </>
  )
}

export default CareerMinis
