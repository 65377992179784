import React from 'react'
import { useScrollPosition } from '../../hooks'
// import { s } from '../../style'

const ScrollDots = ({ refElement, arr, extraCss, onScreen }) => {
  const scroll = useScrollPosition(refElement)
  // const itemWidth = Math.round(scroll.length / arr.length)
  const trueItemWidth = Math.round(scroll.scrollWidth / arr.length)

  // function isInViewportDesktop(id) {
  //   // return (
  //   //   id * itemWidth >= Math.round(scroll.x) + 1 &&
  //   //   (id + 1) * itemWidth <= Math.round(scroll.x) - 1
  //   // )
  //   return id * itemWidth >= scroll.x && id * itemWidth + itemWidth <= scroll.x
  // }

  function isInViewportDesktop(id) {
    return id * trueItemWidth <= scroll.x && (id + 1) * trueItemWidth > scroll.x
  }

  function isInViewport(id) {
    return (
      scroll.x >= id * trueItemWidth &&
      scroll.x < id * trueItemWidth + trueItemWidth
    )
  }

  function scrollIntoId(id) {
    return refElement.current.scrollTo({
      left: id * trueItemWidth,
      behavior: 'smooth',
    })
  }

  // console.log(itemWidth)
  // console.log(trueItemWidth)

  return (
    <>
      <div
        css={[
          extraCss.container,
          onScreen === false && {
            opacity: 0 /* contentVisibility: 'hidden' */,
          },
          {
            transition: 'opacity 0.5s ease-out 0.5s',
            flexWrap: 'wrap',
            maxWidth: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        ]}>
        {arr.map((item, id) => (
          <div
            onClick={() => scrollIntoId(id)}
            onKeyPress={() => scrollIntoId(id)}
            role="button"
            tabIndex={0}
            key={id}
            css={[
              extraCss.inactive,
              isInViewport(id) && extraCss.active,
              scroll.length !== Math.round(scroll.x) &&
                isInViewportDesktop(id) &&
                extraCss.activeDesktop,
              scroll.length === Math.round(scroll.x) &&
                id === arr.length - 1 &&
                extraCss.activeDesktop,
            ]}>
            <div css={[extraCss.dot, { marginBottom: 2 }]} />
          </div>
        ))}
      </div>
      {/* DEV */}
      {/* <p>
        {Math.round(scroll.x)} <span css={{ fontSize: 10 }}>({scroll.x})</span>{' '}
        / length: {scroll.length} / trueLenght: {scroll.scrollWidth} |
        trueItemWidth: {trueItemWidth} | dots: {arr.length}
      </p> */}
    </>
  )
}

export default ScrollDots
