import React from 'react'
import { globals, s, colors, alpha } from '../../style'
import Img from 'gatsby-image'
// import { T } from '../../typo'
import moment from 'moment'
import 'moment/locale/pl'
import 'moment/locale/de'
import 'moment/locale/es'

const IntroBlog = ({
  title,
  subtitle,
  image,
  theme,
  author,
  date,
  language,
}) => {
  // console.log(date)
  // console.log(author)
  return (
    <div>
      <div css={sInnerContainer}>
        {image?.childImageSharp?.fluid && (
          <Img
            style={sImgStyleInTheBackground}
            imgStyle={sImgStyleInTheBackground.img}
            fluid={image.childImageSharp.fluid}
          />
        )}
      </div>
      <div css={sInnerContainer}>
        <div css={sGradientInTheBackground(theme)} />
      </div>
      <div css={sBanner}>
        {image?.childImageSharp?.fluid && (
          <Img
            style={sImageBanner}
            // imgStyle={{ filter: 'blur(16px)' }}
            fluid={image.childImageSharp.fluid}
          />
        )}
        <div css={sGradient(theme)} />
        <div css={[sText, { color: colors['dark'].text }]}>
          <div css={sDetails}>
            {author?.image?.childImageSharp?.fluid && (
              <Img
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 16,
                  marginRight: '0.5rem',
                }}
                fluid={author?.image.childImageSharp.fluid}
                alt={author?.title}
              />
            )}
            <span>{author?.title}</span>
            {date && <span className="bullet" />}
            {date && <span>{moment(date).locale(language).fromNow()}</span>}
          </div>
        </div>
      </div>
    </div>
  )
}

const sGradientInTheBackground = (theme) => ({
  width: '100%',
  left: 100,
  height: '100%',
  position: 'absolute',
  background: `linear-gradient(to bottom, ${colors[theme].background[0].concat(
    alpha[30]
  )}, ${colors[theme].background[1].concat(alpha[100])} 80%)`,
})

const sInnerContainer = {
  zIndex: -1,
  margin: '0 auto',
  position: 'fixed',
  opacity: 1,
  left: -100,
  width: '120vw',

  [s.sm_down]: {
    top: -globals.menu.height.mobile,
    height: `calc(100vh + ${globals.menu.height.mobile}px)`,
  },
  [s.md]: {
    top: -globals.menu.height.desktop,
    height: `calc(100vh + ${globals.menu.height.desktop}px)`,
  },
}

const sImgStyleInTheBackground = {
  width: '100%',
  height: '100%',
  position: 'absoulte',

  img: {
    opacity: 1,
    filter: 'blur(64px) brightness(1)',
  },
}

const sBanner = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  maxWidth: globals.maxWidth,
  margin: '0 auto',
  justifyContent: 'flex-end',

  [s.sm_down]: {
    top: '2rem',
    borderRadius: globals.roundness.sections.mobile,
    boxShadow: globals.shadows.pictures,
    margin: `0 ${globals.spacing.outside.mobile}px`,
    width: `calc(100vw - ${2 * globals.spacing.outside.mobile}px)`,
    height: (100 * 9) / 16 + 'vw',
  },
  [s.md]: {
    borderRadius: globals.roundness.sections.desktop,
    height: (1280 * 9) / 16 / 2,
    // boxShadow: globals.shadows.pictures,
  },
}

const sImageBanner = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
  zIndex: 1,
}

const sText = {
  zIndex: 1,
  // width: '100%',
  [s.sm_down]: {
    padding: `${globals.spacing.inside.mobile}px 12px`,
  },
  [s.md]: {
    padding: globals.spacing.inside.desktop,
    paddingBottom: globals.spacing.inside.desktop / 2,
  },
  textShadow: `0px 0px 24px ${colors.black.concat(alpha[30])}`,
}

const sDetails = {
  [s.sm_down]: {
    paddingTop: globals.spacing.inside.mobile,
  },
  [s.md]: {
    paddingTop: globals.spacing.inside.desktop,
  },
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  span: {
    marginLeft: '0.5rem',
    fontWeight: 700,
    opacity: 1,
    fontSize: '0.875rem',
    [s.md]: { fontSize: '1rem', marginLeft: '1rem' },
    textShadow: `0px 0px 8px ${colors.black.concat(alpha[30])}`,
  },
  '.bullet': {
    width: 6,
    height: 6,
    borderRadius: 4,
    background: colors.white.concat(alpha[60]),
  },
}

const sGradient = (theme) => ({
  zIndex: 1,
  borderRadius: 'inherit',
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: `linear-gradient(to bottom, ${colors.black.concat(
    alpha[0]
  )}, ${colors.black.concat(alpha[30])})`,
})

export default IntroBlog
