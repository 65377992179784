import React, { useRef, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s, colors, alpha } from '../../style'
import { WrapperSection } from '../../wrappers'
// import momonet from 'moment'
import { string_to_slug } from '../../helpers'
import { ScrollDots } from '../../elements'
import { ButtonText } from '../../buttons'
import { useOnScreen } from '../../hooks'
import ScrollContainer from 'react-indiana-drag-scroll'

const BlogMinis = ({ theme, data, removeMarginTop }) => {
  const { slug_section, title, language } = data
  const refBlogs = useRef(null)
  const onScreen = useOnScreen(refBlogs)
  const query = useStaticQuery(graphql`
    {
      blogs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/blog/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              url
              language
              blog_slug
              author
              description
              date
              image {
                name
                publicURL
                childImageSharp {
                  fluid(
                    maxWidth: 400
                    quality: 100
                    toFormat: JPG
                    jpegProgressive: true
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }

      # authors: allMarkdownRemark(
      #   filter: { fileAbsolutePath: { regex: "/authors/" } }
      # ) {
      #   edges {
      #     node {
      #       frontmatter {
      #         title
      #         subtitle
      #         description
      #         image {
      #           name
      #           childImageSharp {
      #             fluid(maxWidth: 300, quality: 100) {
      #               ...GatsbyImageSharpFluid
      #             }
      #           }
      #         }
      #       }
      #     }
      #   }
      # }
    }
  `)

  // const authors = query.authors.edges.map(({ node }) => node.frontmatter)
  const blogs = query.blogs.edges.map(({ node }) => node.frontmatter)
  const blogsExisting = blogs.filter(
    (blog) => blog.title && blog.language === language && blog.image
  )
  // console.log(authors)
  // console.log(blogsExisting)

  const button = {
    text: 'Wszystkie',
    link_page: 'blog-marketingowy',
  }

  useEffect(() => {
    setTimeout(() => refBlogs.current.scrollTo({ left: 1 }), 0)
  }, [])

  return (
    <>
      <WrapperSection
        id={slug_section}
        removeMarginTop={removeMarginTop}
        extraCss={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          [s.md]: {
            marginBottom: '2rem',
          },
          contentVisibility: 'auto',
        }}>
        <T
          d={32}
          m={32}
          o={0.16}
          bold
          condensed
          upper
          extraCss={sHeadline}
          onScreen={onScreen}>
          {title}
        </T>
        {button && (
          <ButtonText
            language={language}
            theme={theme}
            button={button}
            o={0.3}
            extraCss={{ paddingRight: '2rem' }}>
            <T upper bold onScreen={onScreen}>
              {button?.text}
            </T>
          </ButtonText>
        )}
      </WrapperSection>
      {/* <div> */}
      <ScrollContainer
        nativeMobileScroll={true}
        css={[
          sBlogs,
          onScreen === false && {
            opacity: 0 /* contentVisibility: 'hidden' */,
          },
        ]}
        innerRef={refBlogs}>
        {blogsExisting.map((blog, id) => (
          <article
            css={[{ padding: '0 1.5rem' }]}
            key={id}
            itemtype="http://schema.org/Article">
            <Link
              to={`/${
                blog.blog_slug ? blog.blog_slug : string_to_slug(blog.title)
              }`}
              key={id}
              css={[
                sBlog,
                sBlogTheme(theme),
                // !blog.blog_slug && { filter: 'grayscale(1)', opacity: 0.3 },
              ]}>
              <div css={sImage}>
                {blog.image?.childImageSharp?.fluid && (
                  <Img
                    style={sImage.image}
                    fluid={blog.image.childImageSharp.fluid}
                  />
                )}
              </div>
              <div css={sTitle}>{blog.title}</div>
              <div css={sDescription}>{blog.description}</div>
            </Link>
          </article>
        ))}
      </ScrollContainer>
      {/* </div> */}
      <ScrollDots
        onScreen={onScreen}
        refElement={refBlogs}
        arr={blogsExisting}
        extraCss={sDots(theme)}
      />
    </>
  )
}

export const sDots = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 2rem',
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: colors[theme].main,
    [s.xs]: {
      width: 8,
      height: 8,
    },
  },
  inactive: {
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.16,
    padding: `0 6px`,
    [s.xs]: {
      padding: `0 3px`,
    },
    '&:hover': {
      [s.hover]: {
        opacity: 0.6,
      },
    },
  },
  active: {
    [s.xs]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
  activeDesktop: {
    [s.sm]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
})

export const sBlogs = {
  transition: 'opacity 0.3s ease-in-out',
  display: 'flex',
  overflowX: 'auto',
  padding: '2rem 0rem',
  // overflowY: 'hidden',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
  [s.md]: {
    marginBottom: '2rem',
  },
}

export const sHeadline = {
  [s.sm_down]: {
    width: '100%',
    // textAlign: 'center',
    paddingLeft: '2rem',
    marginBottom: '0rem',
  },
  [s.md]: {
    padding: '0 2rem',
    // marginBottom: '2rem'
  },
}

export const sBlog = {
  transition: 'transform 0.25s ease-out, box-shadow 0.25s ease-out',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  flexShrink: 0,
  width: 366,
  height: 344,
  borderRadius: 16,
  [s.xs]: {
    width: 'calc(100vw - 48px)',
  },
  boxShadow: `0px 8px 16px ${colors.black.concat(alpha[4])}`,
  '&:hover': {
    [s.hover]: {
      transform: 'scale(1.05) translateY(-16px)',
      boxShadow: `0px 8px 32px ${colors.black.concat(alpha[16])}`,
    },
  },
}

export const sBlogTheme = (theme) => ({
  backgroundColor: colors[theme].container,
})

export const sImage = {
  position: 'relative',
  width: '100%',
  height: 200,
  borderRadius: '16px 16px 0 0',

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  },
}

export const sTitle = {
  fontSize: 16,
  letterSpacing: -0.3,
  // minHeight: 36,
  fontWeight: 700,
  margin: '1.5rem 1.5rem 1rem',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}
export const sDescription = {
  fontSize: 13,
  letterSpacing: 0,
  maxHeight: 36,
  fontWeight: 400,
  margin: '0rem 1.5rem 1.5rem',
  overflow: 'hidden',
  opacity: 0.6,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}

export default BlogMinis
