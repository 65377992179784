import React from 'react'
import { globals, s } from '../../style'

const WrapperSection = ({ children, id, extraCss, removeMarginTop }) => (
  <section
    id={id}
    css={[sStyle, extraCss, removeMarginTop && sRemoveMarginTop]}>
    {children}
  </section>
)

const sStyle = {
  margin: '0 auto',
  maxWidth: globals.maxWidth,
  [s.sm_down]: {
    marginTop: globals.spacing.between.mobile,
    paddingTop: globals.menu.height.mobile + globals.spacing.outside.mobile,
  },
  [s.md]: {
    paddingLeft: globals.spacing.outside.mobile,
    paddingRight: globals.spacing.outside.mobile,
    marginTop: globals.spacing.between.tablet,
    paddingTop: globals.menu.height.desktop + globals.spacing.outside.desktop,
  },
  [s.lg]: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: globals.spacing.between.desktop,
    paddingTop: globals.menu.height.desktop + globals.spacing.outside.desktop,
  },
  [s.lg_1280]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}

const sRemoveMarginTop = {
  [s.sm_down]: { marginTop: 0, paddingTop: '4rem' },
  [s.md]: { marginTop: 0, paddingTop: '4rem' },
  [s.lg]: { marginTop: 0, paddingTop: '4rem' },
}

export default WrapperSection
